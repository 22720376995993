/* import __COLOCATED_TEMPLATE__ from './smile-branding.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default class SmileBranding extends Component {
  @service
  features;

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {}

  @action
  updateSmileBrandingVisibility(panelChangeset, [showSmileBranding]) {
    this.onChange(
      'panel',
      panelChangeset,
      'customizableShowSmileBranding',
      showSmileBranding
    );
    this.onChange(
      'panel',
      panelChangeset,
      'hideSmileBranding',
      !showSmileBranding
    );
  }
}
