import { template as template_4fb78dfd81b34b4fa7522b4aa201fe90 } from "@ember/template-compiler";
export default template_4fb78dfd81b34b4fa7522b4aa201fe90(`
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.6 1.902s-.18.051-.476.141a3.762 3.762 0 0 0-.23-.565c-.335-.643-.836-.99-1.427-.99-.039 0-.077 0-.129.013C7.325.476 7.3.463 7.287.437 7.03.154 6.696.026 6.297.039c-.77.025-1.542.578-2.159 1.567-.437.694-.77 1.568-.86 2.25-.888.27-1.505.462-1.517.475-.45.141-.463.154-.514.578C1.169 5.231 0 14.291 0 14.291l9.716 1.683V1.876c-.052.013-.09.013-.116.026zm-2.249.694c-.514.154-1.08.334-1.632.501.154-.604.463-1.208.822-1.606.142-.142.334-.309.553-.411.218.462.27 1.092.257 1.516zM6.31.553c.18 0 .334.038.463.115-.206.103-.412.27-.604.463-.489.527-.861 1.35-1.016 2.133l-1.349.412C4.074 2.454 5.114.59 6.31.552zM4.806 7.62c.052.822 2.224 1.002 2.352 2.943.09 1.53-.81 2.57-2.107 2.647-1.568.103-2.43-.822-2.43-.822l.335-1.414s.86.655 1.555.604a.62.62 0 0 0 .604-.655c-.064-1.08-1.838-1.016-1.954-2.79-.102-1.477.874-2.98 3.033-3.122.836-.051 1.26.154 1.26.154l-.489 1.85s-.552-.256-1.208-.205c-.95.064-.963.669-.95.81zm3.06-5.18c0-.385-.052-.937-.232-1.4.59.116.874.771 1.002 1.17-.231.064-.488.14-.77.23zM10.037 15.923l4.035-1.002S12.337 3.187 12.324 3.11a.15.15 0 0 0-.14-.128c-.065 0-1.196-.026-1.196-.026s-.694-.668-.951-.925v13.892z"
      fill="#fff"
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
