/* import __COLOCATED_TEMPLATE__ from './confirm-action.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import BasePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/base';

@classNames('polaris-modals-confirm-action-component')
export default class ConfirmAction extends BasePolarisModal {
  @readOnly('modalParams.title')
  title;

  @readOnly('modalParams.body')
  body;

  @readOnly('modalParams.bodyComponent')
  bodyComponent;

  @readOnly('modalParams.cancelButtonText')
  cancelButtonText;

  @readOnly('modalParams.confirmButtonText')
  confirmButtonText;

  @readOnly('modalParams.confirmButtonTooltipText')
  confirmButtonTooltipText;

  @readOnly('modalParams.confirmButtonDisabled')
  confirmButtonDisabled;

  @readOnly('modalParams.isActionRunning')
  isActionRunning;

  @readOnly('modalParams.destructive')
  destructive;

  @computed('isActionRunning', 'confirm.isRunning')
  get isConfirmRunning() {
    if (isPresent(this.isActionRunning)) {
      return this.isActionRunning;
    } else {
      return this.get('confirm.isRunning');
    }
  }
}
