/* import __COLOCATED_TEMPLATE__ from './billing-provider.hbs'; */
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { resolve } from 'rsvp';

/**
 * Provider component exposes billing business logic backed by the `billing` service.
 * TODO #billing-2018-cleanup get rid of this provider? Since we have the service, this
 * component is only a layer of indirection, we could use the service directly
 */

@tagName('')
export default class BillingProvider extends Component {
  @service
  billing;

  @service
  sesh;

  /**
   * Callback triggered after a billing-subscription offer is activated.
   *
   * @type {Function}
   * @public
   */
  onAfterActivateSubscription = resolve;

  /**
   * Callback triggered after a billing-subscription offer is declined.
   *
   * @type {Function}
   * @public
   */
  onAfterDeclineSubscription = resolve;

  /**
   * Callback triggered after subscribing to a billing-subscription-template.
   *
   * @type {Function}
   * @public
   */
  onAfterSubscribeToSubscriptionTemplate = resolve;

  @reads('billing.isPaymentUpdateRequired')
  isPaymentUpdateRequired;

  @(computed(
    'billing.chargeableSubscription.{isStripeType,isShopifyType}'
  ).readOnly())
  get paymentUpdateRequiredText() {
    let subscription = this.billing.chargeableSubscription;

    if (subscription.isShopifyType) {
      return 'Go to Shopify';
    } else if (subscription.isStripeType) {
      return 'Update credit card';
    }

    return null;
  }

  @(task(function* (subscription, refreshFeatureFlags = true) {
    subscription = yield this.billing.activateSubscription.perform(
      subscription,
      refreshFeatureFlags
    );

    if (!subscription || !subscription.isActive) {
      return;
    }

    yield this.onAfterActivateSubscription();

    return subscription;
  }).drop())
  activateSubscription;

  @(task(function* (subscription) {
    subscription = yield this.billing.declineSubscription.perform(subscription);

    if (!subscription || !subscription.isDeclined) {
      return;
    }

    yield this.onAfterDeclineSubscription();

    return subscription;
  }).drop())
  declineSubscription;

  @(task(function* (subscriptionTemplate, options = {}) {
    let subscription =
      yield this.billing.subscribeToSubscriptionTemplate.perform(
        subscriptionTemplate,
        options
      );

    if (!subscription || !subscription.isActive) {
      return;
    }

    yield this.onAfterSubscribeToSubscriptionTemplate(subscription);

    return subscription;
  }).drop())
  subscribeToSubscriptionTemplate;

  @action
  contactSales(message) {
    this.billing.contactSales(message);
  }
}
