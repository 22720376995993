import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import DataLoaderComponent from '@smile-io/ember-smile-core/components/data-loader';

export default class DataLoader extends DataLoaderComponent {
  @service ajax;
  @service store;

  autoload = true;
  modelName = 'customer';

  /**
   * Overridden data retrieval task to handle
   * searching for customers when a search term
   * is present.
   */
  @(task(function* () {
    return yield this.searchCustomers.perform();
  })
    .restartable()
    .evented())
  _fetchData;

  @(task(function* () {
    let { params, searchTerm } = this;
    let data = Object.assign({}, params, {
      q: searchTerm,
    });

    let { store, ajax } = this;
    let results = yield ajax.request(`/v1/customers/search`, { data });

    store.pushPayload('customer', results);
    let resultsIds = results.customers.map((customer) => customer.id);
    // Normalize results while still maintaining their order
    let normalizedResults = resultsIds.map((customerId) =>
      store.peekRecord('customer', customerId)
    );

    return normalizedResults;
  })
    .restartable()
    .evented())
  searchCustomers;

  didReceiveAttrs() {
    if (this.autoload) {
      super.didReceiveAttrs(...arguments);
    }
  }
}
