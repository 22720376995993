import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Controller.extend({
  alert: service(),
  errorHandler: service(),
  session: service(),
  config: service(),
  router: service(),

  /**
   * True, if the user recovered the password (on `auth.recover` page)
   *
   * @type {Boolean}
   * @default false
   */
  hasRecoveredPassword: false,

  /**
   * True, if the user reset the password (on `auth.reset` page)
   *
   * @type {Boolean}
   * @default false
   */
  hasResetPassword: false,

  /**
   * True, when the token to reset password is invalid or expired.
   *
   * @type {Boolean}
   * @default false
   */
  isResetPasswordTokenExpired: false,

  get shopifyLoginUrl() {
    const attemptedTransitionName = this.session.attemptedTransition?.to?.name;
    const attemptedTransitionPath = attemptedTransitionName
      ? this.router.urlFor(attemptedTransitionName)
      : '';

    return `https://admin.shopify.com/apps/${this.get('config.shopify.clientId')}${attemptedTransitionPath}`;
  },

  authenticate: task(function* (changeset) {
    this.set('loginErrorMessage', '');
    try {
      let email = changeset.get('email');
      let password = changeset.get('password');
      // Persist email, to improve usability, so that when merchants go to recover the
      // password, for example, we prefill it
      this.send('persistAuthData', { email });

      yield this.session.authenticate('authenticator:user', email, password);
    } catch (err) {
      // We use `rejectWithResponse` `true` in our Devise authenticators, to have access to the status code
      // so we have to manually extract the response body here.
      let response = yield err.json();
      if (err.status === 401) {
        this.set('loginErrorMessage', response.error.message);
        return;
      }

      this.errorHandler.handle(response);
    }
  }).drop(),
});
