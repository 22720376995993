/* import __COLOCATED_TEMPLATE__ from './multi-changesets-item.hbs'; */
import { tagName } from '@ember-decorators/component';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import Changeset from 'ember-changeset';
import { isChangeset, isPromise } from 'validated-changeset';
import lookupValidator from 'ember-changeset-validations';
import isObject from 'ember-changeset/utils/is-object';

@tagName('')
export default class MultiChangesetsItem extends Component {
  /**
   * The model/object for which we create a changeset.
   *
   * @type {Object}
   * @public
   */
  object = null;

  /**
   * The validationMap for `object`.
   * This is the validation map for all the properties that need to be validated
   * for `object`. Should leave in `app/validations/<object_name>`.
   *
   * @type {Object}
   * @public
   */
  validationMap = null;

  /**
   * When true, disables auto-validation for the changeset (the default).
   * For more https://github.com/poteto/ember-changeset#disabling-automatic-validation
   *
   * @type {Boolean}
   * @public
   */
  skipValidate = false;

  /**
   * This is the object yielded by {{multi-changesets}} which is required to register/unregister
   * the newly created changeset.
   * @public
   */
  changesets = null;

  changeset = null;

  /**
   * Action invoked when a changeset is initialised.
   */
  onChangesetInitialised() {}

  @task(function* () {
    let { object, validationMap, skipValidate } = this;

    let changeset;
    if (isChangeset(object)) {
      changeset = object;
    } else {
      if (isPromise(object)) {
        object = yield object;
      }

      if (isObject(validationMap)) {
        changeset = new Changeset(
          object,
          lookupValidator(validationMap),
          validationMap,
          { skipValidate }
        );
      } else {
        changeset = new Changeset(object, validationMap, { skipValidate });
      }
    }

    // registerChangeset won't do anything if the changeset is already registered,
    // so we can call it here safely for any changeset we've got at this point.
    this.get('changesets').registerChangeset(changeset);
    this.set('changeset', changeset);

    // Notify the outside world that the changeset got created.
    this.get('onChangesetInitialised')(changeset);
  })
  createChangeset;

  init() {
    super.init(...arguments);

    assert('Missing required `object` param!', this.get('object'));
    assert(
      'Missing required `validationMap` param!',
      this.get('validationMap')
    );
    assert('Missing required `changesets` param!', this.get('changesets'));

    this.get('createChangeset').perform();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.get('changesets').unregisterChangeset(this.get('changeset'));
  }
}
