/* import __COLOCATED_TEMPLATE__ from './smile-ui-misconfiguration.hbs'; */
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { capitalize } from '@ember/string';

@tagName('')
export default class SmileUiMisconfiguration extends Component {
  @service
  sesh;

  @service
  router;

  @readOnly('sesh.account.platformType')
  platformType;

  @computed('platformType')
  get learnMoreUrl() {
    let learnMoreUrlPath = '';

    learnMoreUrlPath = this.get('sesh.platformIntegration.isStencilEnabled')
      ? 'bigcommerce/applying-smile-on-bigcommerce-with-stencil'
      : 'bigcommerce/applying-smile-on-bigcommerce-with-blueprint';

    return `https://help.smile.io/apps-and-platforms/${learnMoreUrlPath}`;
  }

  @computed('platformType')
  get secondaryCtaEvent() {
    let platformType = this.get('platformType');
    let platformTypeFormatted = '';

    if (platformType === 'bigcommerce') {
      // Determine if they are on Stencil
      platformTypeFormatted =
        this.get('sesh.platformIntegration.isStencilEnabled') === true
          ? `${capitalize(platformType)} Stencil`
          : `${capitalize(platformType)} Blueprint`;
    } else {
      platformTypeFormatted = capitalize(platformType);
    }

    return `KB - Apply Smile on ${platformTypeFormatted} clicked`;
  }

  @action
  transitionToPlatformSettings() {
    this.router.transitionTo('settings.platform', {
      queryParams: { showDetailsOnLoad: true },
    });
  }
}
