import { attributeBindings, classNames } from '@ember-decorators/component';
import { cached } from '@glimmer/tracking';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { scheduleOnce } from '@ember/runloop';
import $ from 'jquery';

/**
 * Ember port of Mohammad Younes's jquery-scrollLock plugin,
 * see https://github.com/MohammadYounes/jquery-scrollLock.
 */

const keys = {
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  up: 38,
  down: 40,
};

@attributeBindings('tabIndex:tabindex')
@classNames('scroll-lock')
export default class ScrollLock extends Component {
  eventNamespace = '.scroll-lock';

  /**
   * Settings - see jquery-scrollLock documentation for info.
   */
  strict = false;

  selector = false;

  // animation: false, // no animation support at present.
  touch = true;

  keyboard = false;
  unblock = false;

  /** @private */
  enabled = true;

  startClientY = 0;

  @(computed('keyboard').readOnly())
  get tabIndex() {
    const keyboard = this.get('keyboard');

    if (!keyboard) {
      return false;
    }

    return keyboard.tabindex || 0;
  }

  @cached
  get wheelEventName() {
    // Modern browsers support "wheel".
    if ('onwheel' in document.createElement('div')) {
      return 'wheel';
    }

    // Webkit and IE support at least "mousewheel".
    if (document.onmousewheel !== undefined) {
      return 'mousewheel';
    }

    // Let's assume that remaining browsers are older Firefox.
    return 'DOMMouseScroll';
  }

  /**
   * "Filter" functions for deciding whether to prevent or allow events.
   */
  strictFn($element) {
    return $element.prop('scrollHeight') > $element.prop('clientHeight');
  }

  preventTouchMove(event) {
    return !$(event.target).hasClass('scroll-lock-allow-touchmove');
  }

  /**
   * Control methods.
   */
  toggleStrict() {
    this.toggleProperty('strict');
  }

  enable() {
    this.set('enabled', true);
  }

  disable() {
    this.set('enabled', false);
  }

  /**
   * Helper methods.
   */
  getNamespacedEvent(eventName) {
    return `${eventName}${this.get('eventNamespace')}`;
  }

  /**
   * Event handlers.
   */
  unblockHandler(event) {
    event.__currentTarget = event.currentTarget;
  }

  handler(event) {
    // allow zooming
    if (this.get('enabled') && !event.ctrlKey) {
      const $this = $(event.currentTarget);

      if (this.get('strict') !== true || this.strictFn($this)) {
        // Support for nested scrollable blocks (see https://github.com/MohammadYounes/jquery-scrollLock/issues/4)
        event.stopPropagation();
        const result = this.processScrollEvent(event, $this);

        if (event.__currentTarget) {
          result.prevent &= this.processScrollEvent(
            event,
            $(event.__currentTarget)
          ).prevent;
        }

        if (result.prevent) {
          event.preventDefault();

          if (result.deltaY) {
            $this.scrollTop(result.scrollTop + result.deltaY);
          }

          //  No support for animation and events at the moment.
          //  const key = result.top ? 'top' : 'bottom';
          //  if (this.options.animation) {
          //    setTimeout(ScrollLock.CORE.animationHandler.bind(this, $this, key), 0)
          //  }
          //  $this.trigger($.Event(key + ScrollLock.NAMESPACE))
        }
      }
    }
  }

  processScrollEvent(event, $element) {
    const scrollTop = $element.scrollTop();
    const scrollHeight = $element.prop('scrollHeight');
    const clientHeight = $element.prop('clientHeight');
    let delta =
      event.originalEvent.wheelDelta ||
      -1 * event.originalEvent.detail ||
      -1 * event.originalEvent.deltaY;
    let deltaY = 0;
    let preventTouchMove = true;

    if (event.type === 'wheel') {
      const ratio = $element.height() / $(window).height();
      deltaY = event.originalEvent.deltaY * ratio;
    } else if (this.get('touch') && event.type === 'touchmove') {
      delta =
        event.originalEvent.changedTouches[0].clientY -
        this.get('startClientY');
      preventTouchMove = this.preventTouchMove(event);
    }

    let top, prevent;
    prevent =
      (top = delta > 0 && scrollTop + deltaY <= 0) ||
      (delta < 0 && scrollTop + deltaY >= scrollHeight - clientHeight);
    prevent &= preventTouchMove;

    return {
      prevent,
      top,
      scrollTop,
      deltaY,
    };
  }

  touchHandler(event) {
    this.set('startClientY', event.originalEvent.touches[0].clientY);
  }

  keyboardHandler(event) {
    const $this = $(event.currentTarget);
    // const scrollTop = $this.scrollTop();
    const result = this.processKeyboardEvent(event, $this);

    if (event.__currentTarget) {
      const result2 = this.processKeyboardEvent(
        event,
        $(event.__currentTarget)
      );
      result.top &= result2.top;
      result.bottom &= result2.bottom;
    }

    //  No event/animation support at the moment.
    return false;
    //  if (result.top) {
    //    $this.trigger($.Event('top' + ScrollLock.NAMESPACE))
    //    if (this.options.animation) {
    //      setTimeout(ScrollLock.CORE.animationHandler.bind(this, $this, 'top'), 0)
    //    }
    //    return false
    //  } else if (result.bottom) {
    //    $this.trigger($.Event('bottom' + ScrollLock.NAMESPACE))
    //    if (this.options.animation) {
    //      setTimeout(ScrollLock.CORE.animationHandler.bind(this, $this, 'bottom'), 0)
    //    }
    //    return false
    //  }
  }

  processKeyboardEvent(event, $element) {
    const scrollTop = $element.scrollTop();
    const result = {
      top: false,
      bottom: false,
    };

    result.top =
      scrollTop === 0 &&
      (event.keyCode === keys.pageup ||
        event.keyCode === keys.home ||
        event.keyCode === keys.up);
    if (!result.top) {
      const scrollHeight = $element.prop('scrollHeight');
      const clientHeight = $element.prop('clientHeight');
      result.bottom =
        scrollHeight === scrollTop + clientHeight &&
        (event.keyCode === keys.space ||
          event.keyCode === keys.pagedown ||
          event.keyCode === keys.end ||
          event.keyCode === keys.down);
    }

    return result;
  }

  setupListeners() {
    // Set up event handlers.
    const wheelEventName = this.get('wheelEventName');
    const unblock = this.get('unblock');
    const selector = this.get('selector');
    const $this = $(this.element);

    if (unblock) {
      $this.on(
        this.getNamespacedEvent(wheelEventName),
        unblock,
        this.unblockHandler.bind(this)
      );
    }

    $this.on(
      this.getNamespacedEvent(wheelEventName),
      selector,
      this.handler.bind(this)
    );

    if (this.get('touch')) {
      $this.on(
        this.getNamespacedEvent('touchstart'),
        selector,
        this.touchHandler.bind(this)
      );
      $this.on(
        this.getNamespacedEvent('touchmove'),
        selector,
        this.handler.bind(this)
      );
    }

    const keyboard = this.get('keyboard');
    if (keyboard) {
      $this.on(
        this.getNamespacedEvent('keydown'),
        selector,
        this.keyboardHandler.bind(this)
      );

      if (unblock) {
        $this.on(
          this.getNamespacedEvent('keydown'),
          unblock,
          this.unblockHandler.bind(this)
        );
      }
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    scheduleOnce('afterRender', this, this.setupListeners);
  }

  willDestroyElement() {
    $(this.element).off(this.get('eventNamespace'));

    super.willDestroyElement(...arguments);
  }
}
