/* import __COLOCATED_TEMPLATE__ from './colors-dropdown.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';

@tagName('')
export default class ColorsDropdown extends Component {
  @tracked _options;

  /**
   * Array of objects used to render the list
   * of colors in the popover.
   *
   * ex: { label: 'Primary', value: 'primary', hex: '#FF000' }
   * ex: { label: 'White', value: '#FFFFFF', hex: '#FFFFFF' }
   *
   * @property options
   * @public
   * @type {Object[]}
   * @default []
   */
  @computed('_options')
  get options() {
    if (this._options) {
      return this._options;
    }
    return [];
  }

  set options(value) {
    this._options = value;
  }

  /**
   * Action called when a color is selected
   * from the list in the dropdown.
   *
   * @property onSelect
   * @public
   * @type {Function}
   * @default noop
   */
  onSelect() {}

  @action
  handleSelect(colorOption) {
    this.set('selected', colorOption);
    this.get('onSelect')(colorOption.value);
  }
}
