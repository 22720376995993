/* import __COLOCATED_TEMPLATE__ from './shape-dropdown.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';

const options = [
  {
    label: 'Square',
    value: 'square',
    icon: 'smile/smile-ui/shape-square',
  },
  {
    label: 'Shaved',
    value: 'shaved',
    icon: 'smile/smile-ui/shape-shaved',
  },
  {
    label: 'Rounded',
    value: 'rounded',
    icon: 'smile/smile-ui/shape-rounded',
  },
  {
    label: 'Circular',
    value: 'circular',
    icon: 'smile/smile-ui/shape-circular',
  },
];

@tagName('')
export default class ShapeDropdown extends Component {
  /**
   * Label above the dropdown
   *
   * @property label
   * @public
   * @type {String}
   * @default null
   */
  label = null;

  /**
   * The currently chosen option object
   *
   * @property selected
   * @public
   * @type {Object}
   * @default null
   */
  selected = null;

  /**
   * Action called when a shape is chosen
   *
   * @property onSelect
   * @public
   * @type {Function}
   * @default noop
   */
  onSelect() {}

  /**
   * Array of objects used to render the list
   * of shapes
   *
   * ex: { label: 'Square', icon: 'smile/smile-ui/shape-square' }
   *
   * @property options
   * @private
   * @type {Object[]}
   * @default (hard coded)
   */
  options = options;

  @action
  handleSelect(shapeOption) {
    this.set('selected', shapeOption);
    this.get('onSelect')(shapeOption.value);
  }
}
