/* import __COLOCATED_TEMPLATE__ from './show-nudges.hbs'; */
import { action } from '@ember/object';
import { not } from '@ember/object/computed';
import Component from '@ember/component';
import { storageFor } from 'ember-local-storage';

export default class ShowNudges extends Component {
  @storageFor('sweet-tooth-session')
  localStorage;

  @not('localStorage.dismissedNudgesCard')
  shouldRenderNudgesCard;

  @action
  dismiss() {
    let dismiss = this.onDismiss;

    if (dismiss) {
      dismiss('dismissedNudgesCard');
    }
  }
}
