/* import __COLOCATED_TEMPLATE__ from './customer-short-bio.hbs'; */
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';

@tagName('')
export default class CustomerShortBio extends Component {
  /**
   * @property customerName
   * @type {String}
   * @public
   * @required
   **/
  customerName = '';

  /**
   * @property subText
   * @type {String}
   * @public
   **/
  subText = '';

  init() {
    super.init(...arguments);
    assert(
      '[customer-short-bio] Customer name should be passed',
      isPresent(this.customerName)
    );
  }
}
