/* import __COLOCATED_TEMPLATE__ from './new-feature-release.hbs'; */
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';

@tagName('')
export default class NewFeatureRelease extends Component {
  @service
  store;

  shouldRender = true;

  @readOnly('homeCard.featureRelease')
  featureRelease;

  @(task(function* (featureRelease) {
    this.set('shouldRender', false);

    yield featureRelease.dismiss();
  }).drop())
  dismissFeatureRelease;
}
