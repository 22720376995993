/* import __COLOCATED_TEMPLATE__ from './colors.hbs'; */
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { or } from '@ember/object/computed';
import Component from '@ember/component';

const colorPropertiesToValidate = [
  'customizablePrimaryColor',
  'customizableSecondaryColor',
];

@tagName('')
export default class Colors extends Component {
  @service
  sesh;

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {}

  /**
   * Action fired when a color picker popover is closed.
   *
   * @property {onColorPopupClosed}
   * @type {Function}
   * @default no-op
   * @public
   */
  onColorPopupClosed() {}

  /**
   * Hash of the latest valid colors chosen by the merchant.
   * We keep track of these so that we can restore them if they
   * reopen a color picker popup and enter an invalid value.
   *
   * @property {latestValidColors}
   * @type {Object}
   * @private
   */
  latestValidColors = null;

  /**
   * Changeset around the `display-setting` model
   * backing the merchant's theme.
   *
   * @property {displaySettingChangeset}
   * @type {Changeset}
   * @private
   */
  displaySettingChangeset = null;

  panelHeaderChangeset = null;
  launcherChangeset = null;
  customerNotificationThemeChangeset = null;

  @or(
    'displaySettingChangeset.customizablePrimaryColor',
    'displaySettingChangeset.primaryColor'
  )
  primaryColor;

  @or(
    'displaySettingChangeset.customizableSecondaryColor',
    'displaySettingChangeset.secondaryColor'
  )
  secondaryColor;

  @computed('primaryColor', 'secondaryColor')
  get savedColorsOptions() {
    let { primaryColor, secondaryColor } = this;

    return [
      {
        value: 'primary',
        label: 'Primary',
        hex: primaryColor,
      },
      {
        value: 'secondary',
        label: 'Secondary',
        hex: secondaryColor,
      },
    ];
  }

  initLatestValidColors() {
    let displaySettingChangeset = this.get('displaySettingChangeset');
    let colorProperties = displaySettingChangeset.getProperties(
      ...colorPropertiesToValidate
    );
    this.set('latestValidColors', colorProperties);
  }

  ensureColorPropertyIsValid(displaySettingChangeset, propertyName) {
    if (!displaySettingChangeset.get(`error.${propertyName}`)) {
      return;
    }

    // Restore the most recent valid value if an invalid
    // color is chosen for the supplied property.
    displaySettingChangeset.set(
      propertyName,
      this.get(`latestValidColors.${propertyName}`)
    );
  }

  willDestroyElement() {
    let displaySettingChangeset = this.get('displaySettingChangeset');
    colorPropertiesToValidate.forEach((propertyName) => {
      this.ensureColorPropertyIsValid(displaySettingChangeset, propertyName);
    });

    super.willDestroyElement(...arguments);
  }

  @action
  onDisplaySettingChangesetInitialised(displaySettingChangeset) {
    this.set('displaySettingChangeset', displaySettingChangeset);

    // Make a note of the current colors (which should be valid...)
    // as soon as we receive the `display-setting` changeset.
    this.initLatestValidColors();
  }

  @action
  colorPopupClosed(displaySettingChangeset, propertyName) {
    this.ensureColorPropertyIsValid(displaySettingChangeset, propertyName);

    this.onColorPopupClosed(displaySettingChangeset, propertyName);
  }

  @action
  updateColorProperty(displaySettingChangeset, propertyName, value) {
    this.onChange(
      'displaySetting',
      displaySettingChangeset,
      propertyName,
      value
    );

    // Make a note of the new value if it's valid, so that
    // we can restore it if the merchant invalidates the color.
    if (!displaySettingChangeset.get(`error.${propertyName}`)) {
      this.set(
        `latestValidColors.${propertyName}`,
        displaySettingChangeset.get(propertyName)
      );
    }
  }

  @action
  updateThemeProperty(displaySettingChangeset, value) {
    this.onChange(
      'displaySetting',
      displaySettingChangeset,
      'customizableTheme',
      value
    );
  }

  @action
  setAllFontColours(colour) {
    let {
      displaySettingChangeset,
      customerNotificationThemeChangeset,
      panelHeaderChangeset,
      launcherChangeset,
    } = this;

    // We need to update both the customizable and non-customizable
    // properties for most of these - the former so that we update
    // values in the DB on save, and the latter so that the preview
    // updates correctly.
    displaySettingChangeset.setProperties({
      customizableButtonFontColor: colour,
      buttonFontColor: colour,
    });

    customerNotificationThemeChangeset.setProperties({
      customizableButtonFontColor: colour,
      buttonFontColor: colour,
    });

    panelHeaderChangeset.setProperties({
      customizableBannerFontColor: colour,
      customizableHeaderBarFontColor: colour,
    });

    launcherChangeset.setProperties({
      customizableTextColor: colour,
      textColor: colour,
    });
  }
}
